@import url('https://fonts.googleapis.com/css2?family=Caveat&family=Kalam:wght@300;400;700&display=swap');

* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    --color-bg:#000000;
    --color-bg-variant: #0d0f1a;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6);

    --transition: all .9s ease-in-out;

    --container-width-lg: 75%;
    --container-width-md: 85%;
    --container-width-sm: 90%;
}

html {
    scroll-behavior: smooth;
}

::-webkit-scrollbar{
    display: none;
}

body {
    background: var(--color-bg);
    color: var(--color-white);
    line-height: 2;
    font-family: 'Caveat', cursive;
    font-family: 'Kalam', cursive;
}

.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1,h2,h3,h4,h5 {
    font-weight: 400;
}

h1 {
    font-size: 2.6rem;
}

section {
    margin-top: 8rem;
}

section > h2,
section > h5 {
    text-align: center;
    color: var(--color-primary);
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-align {
    color: var(--color-light);
}

a {
    color: var(--color-primary);
    transition: var(--transition);
}

a:hover {
    color: var(--color-white);
}

.btn {
    display: inline-block;
    color: var(--color-primary);
    padding: 0.75rem 1.25rem;
    border-radius: 0.4rem;
    cursor: pointer;
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-bg-variant);
    color: var(--color-white);
}

.btn-primary {
    background: var(--color-bg-variant);
}

img {
   display: block;
   width: 100%; 
   object-fit: cover;
}

/* Media queries for medium device */

@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
}

section > h2{
    margin-top: 6rem;
 }
}

@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
}

section > h2{
    margin-top: 2rem;
 }
}
