footer {
    background: var(--color-bg-variant);
    padding: 3rem 0;
    text-align: center;
    font-size: 0.9rem;
    margin-top: 7rem;
}

footer a {
    color: var(--color-primary);
}

.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}

.footer__copyright {
    margin-bottom: 4rem;
    color: var(--color-primary);
}

@media screen and (max-width:600px) {
    .permalinks {
        flex-direction: column;
        gap: 1.5rem;
    }
}