header 
{
    height: 100vh;
    padding: 7rem;
    overflow: hidden;
}

.header__container {
    text-align:center;
    height: 100%;
    position: relative;
}

.call-to-action
{
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}


.header__socials
{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

@media screen and (max-width: 1024px) {
    header {
        height: 68vh;
    }
}


@media screen and (max-width: 600px) {
    header {
        height: 100vh;
    }
    .header__socials,
     .scroll__down {
        display: none;
    }
}

